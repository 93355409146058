import { request } from '@fingermarkglobal/request';
import i18n from 'i18next';
import { validateEvent } from '@fingermarkglobal/event-store';
import es from './event-store-client';

const defaultRequest = additionalPayload => {
  try {
    const payload = [];

    payload.push(`v=1`);
    payload.push(`tid=${process.env.POI_APP_GOOGLE_ANALYTICS}`);
    payload.push(`sr=${window.screen.width}x${window.screen.height}`);
    payload.push('geoid=AE'); // this needs to come from settings
    payload.push(`dt=${encodeURIComponent(window.document.title)}`);
    payload.push(`ul=${i18n.language}`);

    request.get(
      `https://www.google-analytics.com/collect?${payload.join('&')}${additionalPayload}`,
      {
        'User-Agent': 'FingermarkKiosk',
        crossorigin: 'anonymous',
        prefixUrl: '',
      },
    );
  } catch (err) {
    // warn instead of error as GA is fire and forget
    // any errors dont impact app and usually happen randomly when GA times out etc
    // If this occurs more often we can investigate further
    logger.warn(`[@fingermarkglobal/google-analytics][defaultRequest] GA catch error: ${err}`);
  }
};

const publishEvent = payload => {
  if (!validateEvent(payload)) {
    throw Error('originalPayload is not a valid event for the event store');
  }

  es.appendEvents(payload.aggregate_type, null, [payload]);
};
export { defaultRequest, publishEvent };
