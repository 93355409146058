import { assign } from 'xstate';

const handlePayment = assign((context, event) => {
  const { data } = event || {};
  const { fields = {} } = data || {};

  const info = {
    transactionId: fields.ecrRcptNum,
    authCode: fields.approvalCode,
    cardNumber: fields.cardNumber,
    cardType: fields.cardSchemeName,
    amount: parseFloat(fields.amount) / 100,
    messNumber: fields.messNumber,
    ecrRcptNum: fields.ecrRcptNum,
    terminalId: fields.tid,
    merchantId: fields.mid,
    aid: fields.emvAid,
    paymentType: 'CARD',
    response: fields,
  };

  logger.info('[@fingermarkglobal/marshal] Payment transaction finished');

  return {
    ...context,
    transactionId: info.transactionId,
    timestamp: fields.datetime,
    result: 'SUCCESS',
    info,
    message: fields.responseMessage,
  };
});

export { handlePayment };
