import { validate } from '@fingermarkglobal/validation';
import { interpret as original } from 'xstate';

const interpret = ({ name = null, machine = null, transition = null } = {}) => {
  validate({ name: 'interpret', parameters: { name, machine } });

  const instance = original(machine)
    .onTransition(state => {
      // This is essentially a way to 'reduce' cruft in the logs while still giving visibility
      // - Any situation where this is not sufficient would warrant dev intervention anyway
      // - At which point logs could be expanded upon in a local environment
      const context = Object.entries(state?.context || {}).reduce((result, [key, value]) => {
        const populated = !value ? 'Empty' : 'Populated';
        return {
          ...result,
          [key]: populated,
        };
      }, {});

      logger.debug(
        `[@fingermarkglobal/utilities] ${name} event: ${state?.event?.type}, state: ${state.value}`,
      );
      logger.debug(
        `[@fingermarkglobal/utilities] ${name} context (show's only populated fields not actual values):`,
        context,
      );
      if (transition) transition(state);
    })
    .start();

  const run = ({ event = null, config = null } = {}) => {
    validate({ name: 'run', parameters: { event } });
    instance.send(event, config);
  };

  return {
    run,
    instance,
  };
};

export { interpret };
