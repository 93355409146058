import { assign } from 'xstate';
import moment from 'moment';
import { buildMessage } from '../../../utilities/builders';

const buildParams = ({ tid, amount } = {}) => {
  return {
    isRequestOrResponse: 'request',
    transactionName: 'auth',
    tid,
    ecrNumber: String(moment().format('YMDHms')),
    amount,
  };
};

const createPayload = assign((context, { amount, config }) => {
  const { settings = {} } = config || {};
  const { terminalId } = settings;

  logger.info('[@fingermarkglobal/marshal] Payment transaction started...');

  logger.debug(`[@fingermarkglobal/marshal][createPayload] config is ${JSON.stringify(config)}`);

  const params = buildParams({ tid: terminalId, amount });

  logger.debug(`[@fingermarkglobal/marshal][createPayload]: params are ${JSON.stringify(params)}`);

  const buffer = buildMessage(params);

  logger.debug(`[@fingermarkglobal/marshal][createPayload]: payload is [${buffer}]`);

  return {
    config,
    buffer,
  };
});

export { createPayload };
