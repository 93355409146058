import { useCallback, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { cartState } from '../../atoms';
import { evaluate } from '../../utilities/evaluate';
import { format } from '../../utilities/format';
import { reformat } from '../../utilities/reformat';
import { createUpdater } from '../../utilities/update';

const useStage = () => {
  const cart = useRecoilValue(cartState);
  const [staged, setStaged] = useState();
  const stagedRef = useRef();

  const setStagedHandler = useCallback(valueOrUpdater => {
    const contextValue = stagedRef.current;

    if (typeof valueOrUpdater === 'function') {
      stagedRef.current = evaluate({ product: valueOrUpdater(contextValue) });
    } else {
      stagedRef.current = evaluate({ product: valueOrUpdater });
    }

    logger.debug('[@fingermarkglobal/cart][setStagedHandler] Staged:', {
      id: stagedRef.current?.productId,
      name: stagedRef.current?.name?.text,
    });

    setStaged(stagedRef.current);
  }, []);

  /**
   * Stage a product by using one of the parameters in the following order:
   * - If uid is present, it will find this uid in the cart to stage;
   * - If staged is present, it will reformat the item to stage;
   * - Otherwise, it will format this hydrated product to stage.
   */
  const stage = useCallback(
    ({ product, staged, uid } = {}) => {
      const update = createUpdater({ set: setStagedHandler });

      if (uid) {
        const cartItem = cart.find(item => item.uid === uid);
        setStagedHandler(reformat({ product: cartItem, update, isRootProduct: true }));
      } else if (staged) {
        setStagedHandler(reformat({ product: staged, update, isRootProduct: true }));
      } else {
        setStagedHandler(format({ product, update, isRootProduct: true }));
      }
    },
    [cart, setStagedHandler],
  );

  return { stage, staged };
};

export { useStage };
