import { destructPayment, destructStatus } from '../../destructerers';
import { executeRequest } from '../request';

const executePayment = async context => {
  // take built payload
  const { config } = context;

  let adyenResponse;
  let status = false;

  // make request
  logger.info(
    `[@fingermarkglobal/adyen][executePayment] payment request: ${JSON.stringify(
      context,
      null,
      2,
    )}`,
  );

  try {
    adyenResponse = await executeRequest({ context });

    logger.info(
      `[@fingermarkglobal/adyen][executePayment] payment response: ${JSON.stringify(
        adyenResponse,
        null,
        2,
      )}`,
    );

    // handle destruct
    // return standard format
    const { result, errorCondition, response, transactionId, timestamp, info } = destructPayment({
      response: adyenResponse,
      config,
      status,
    });

    if (result?.toLowerCase() !== 'success') {
      throw new Error('Payment failed.');
    }

    return {
      result,
      message: errorCondition,
      errorCondition,
      response,
      transactionId,
      timestamp,
      info,
    };
  } catch (error) {
    logger.warn(
      `[@fingermarkglobal/adyen][executePayment] payment request failed. trying to get transaction status`,
    );

    adyenResponse = await executeRequest({ context, status: true });

    status = true;

    logger.info(
      `[@fingermarkglobal/adyen][executePayment] status response: ${JSON.stringify(
        adyenResponse,
        null,
        2,
      )}`,
    );

    // handle destruct
    // return standard format
    const { result, errorCondition, response, transactionId, timestamp, info } = destructStatus({
      response: adyenResponse,
      config,
      status,
    });

    if (result?.toLowerCase() !== 'success') {
      // Logging here for visibility, error can get obfuscated via machine
      logger.error('[@fingermarkglobal/adyen] Refusal reason', errorCondition);
      throw new Error(errorCondition);
    }

    return {
      result,
      message: errorCondition,
      errorCondition,
      response,
      transactionId,
      timestamp,
      info,
    };
  }
};

export { executePayment };
