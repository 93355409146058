// destruct unstructures the data from the "ui" format
// categories, handlers etc
const destruct = product => {
  const { options = [], customisations = [] } = product;

  const reduceOptions = option => {
    return option.products.filter(item => item.selected);
  };

  const reduceCategories = category => {
    const products = category.products.filter(item => item.default || item.selected);
    return products.map(product => ({ ...product, categoryId: category.categoryId }));
  };

  const opts = options.map(reduceOptions).flat();

  return {
    ...product,
    options: opts.map(destruct),
    customisations: customisations.map(reduceCategories).flat(),
  };
};

export { destruct };
