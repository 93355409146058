import { PreOrderPayload, SaveOrderPayload, PlaceOrderPayload } from '@fingermarkglobal/types';

type MergedPayload = PreOrderPayload | SaveOrderPayload | PlaceOrderPayload;
interface RequestOptions {
  endpoint: string;
  params: {
    json: any;
    headers: any;
    timeout: number;
  };
}

const buildRequestOptions = (
  endpoint: string,
  payload: MergedPayload,
  token: string,
  timeout: number = 30,
  ip?: string,
  local?: boolean,
): RequestOptions => {
  const formattedEndpoint: string = local && ip ? endpoint.replace('{ip}', ip) : endpoint;

  return {
    endpoint: formattedEndpoint,
    params: {
      json: payload,
      timeout: timeout * 1000,
      headers: { Authorization: `Bearer ${token}` },
      ...(local && { prefixUrl: '' }),
    },
  };
};

export { buildRequestOptions };
