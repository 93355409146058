import { decryptResponse } from '../request';

const destructStatus = ({ response = null, config = {} } = {}) => {
  const payload = decryptResponse({ response, config });

  logger.info(
    `[@fingermarkglobal/adyen][destructStatus] status decrypted payload response: ${JSON.stringify(
      payload,
      null,
      2,
    )}`,
  );

  const { SaleToPOIResponse } = payload || {};
  const { TransactionStatusResponse } = SaleToPOIResponse || {};
  const { RepeatedMessageResponse } = TransactionStatusResponse || {};
  const { RepeatedResponseMessageBody } = RepeatedMessageResponse || {};
  const { PaymentResponse, ReversalResponse } = RepeatedResponseMessageBody || {};

  if (PaymentResponse) {
    const { Response, POIData, PaymentResult } = PaymentResponse || {};
    const { Result = '', AdditionalResponse = '' } = Response || {};
    const { POITransactionID = {} } = POIData || {};
    const { TimeStamp: timestamp = null, TransactionID: transactionId = null } =
      POITransactionID || {};

    const parsedAdditionalResponse = new URLSearchParams(AdditionalResponse);
    const refusalReason = parsedAdditionalResponse.get('refusalReason') || 'No refusal reason';

    const { PaymentAcquirerData, PaymentInstrumentData, AmountsResp } = PaymentResult || {};
    const { AuthorizedAmount: amount = null } = AmountsResp || {};
    const { ApprovalCode: authCode = '' } = PaymentAcquirerData || {};
    const { CardData } = PaymentInstrumentData || {};
    const { PaymentBrand: cardType = null, MaskedPan: cardNumber = null } = CardData || {};

    // cancelled, notallowed, success
    const result = Result?.toLowerCase();
    const errorCondition = refusalReason || null;

    const info = {
      paymentType: 'CARD',
      transactionId,
      authCode,
      cardNumber,
      cardType,
      amount: parseFloat(amount),
    };

    return { response: payload, result, timestamp, transactionId, info, errorCondition };
  }
  if (ReversalResponse) {
    const { Response } = ReversalResponse;
    const { Result, ErrorCondition } = Response;

    // cancelled, notallowed, success
    const result = Result?.toLowerCase();
    const errorCondition = ErrorCondition?.toLowerCase() || null;

    return { response: payload, result, errorCondition };
  }

  return {};
};

export { destructStatus };
