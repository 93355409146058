import { assign } from 'xstate';
import { decryptResponse } from '../request';

const handleRefund = assign((context, event) => {
  const { config } = context;
  const { response, result, errorCondition, timestamp, transactionId } = event.data;

  return {
    timestamp,
    transactionId,
    result,
    config,
    response,
    message: errorCondition,
  };
});

const destructRefund = ({ response = null, config = {} } = {}) => {
  const payload = decryptResponse({ response, config });

  logger.info(
    `[@fingermarkglobal/adyen][destructRefund] refund decrypted payload response: ${JSON.stringify(
      payload,
      null,
      2,
    )}`,
  );

  const { SaleToPOIResponse } = payload;
  const { ReversalResponse } = SaleToPOIResponse;
  const { Response } = ReversalResponse;
  const { Result, ErrorCondition } = Response;

  // cancelled, notallowed, success
  const result = Result?.toLowerCase();
  const errorCondition = ErrorCondition?.toLowerCase() || null;

  return { response: payload, result, errorCondition };
};

export { destructRefund, handleRefund };
