import { sender } from '@fingermarkglobal/chrome-messages';
import { validate } from '@fingermarkglobal/validation';

class HTTPClient {
  constructor({ port = null, config = { timeout: 30 } } = {}) {
    validate({ name: 'HTTPClient', parameters: { port } });
    this.post = sender({ port, config });
  }

  // current payload structure is based on Axios
  // https://bitbucket.org/fingermarkltd/supersonic-slamurai/src/develop/src/services/http/index.js
  // TODO: change this over to a standard system / structure for our `requests` library
  async send({ payload = null } = {}) {
    const request = {
      type: 'http',
      payload,
    };

    try {
      logger.info(`[@fingermarkglobal/protocols] HTTP Request sent: ${request}`);
      const { payload: body } = await this.post(request);
      const { response = {} } = body;

      logger.info(`[@fingermarkglobal/protocols] HTTP Response received: ${response}`);

      return response;
    } catch (err) {
      logger.error('[@fingermarkglobal/protocols] Error in send:', err);
      throw err;
    }
  }
}

export { HTTPClient };
