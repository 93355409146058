import { request, HTTPError, TimeoutError } from '@fingermarkglobal/request';

const pingHealthService = async ({ serial, settings }) => {
  try {
    if (!serial) throw new Error(`Invalid 'serial' passed to 'pingHealthService'`);
    if (!settings) throw new Error(`Invalid 'settings' passed to 'pingHealthService'`);

    const formattedSerial = `${serial}`.replace(':', '');
    const { customer: organisation } = settings?.settings?.datasource || {};
    const endpoint = process.env.POI_APP_HEARTBEAT_API;

    if (!endpoint || !organisation) return null;

    return request
      .post(endpoint, {
        prefixUrl: '',
        json: {
          config: {
            pretty: true,
            exit_status: 0,
            type: 'Service',
            performance_data: [],
            plugin_output: 'PING OK - Packet loss = 0%',
            filter: `host.name=="${formattedSerial}.${organisation}.fingermark.tech"`,
          },
        },
      })
      .json();
  } catch (err) {
    if (err instanceof HTTPError || err instanceof TimeoutError) return null;
    logger.error('[@fingermarkglobal/health] Error in pingHealthService:', err);
  }

  return null;
};

const checkOnlineStatus = async () => {
  if (!window.navigator.onLine) return false;

  try {
    const url = new URL(window.location.origin);
    url.searchParams.set('q', new Date().toString());

    const response = await request.get(url.toString(), {
      prefixUrl: '',
      method: 'HEAD',
    });

    return response.ok;
  } catch (err) {
    logger.error('[@fingermarkglobal/health] Error in checkOnlineStatus:', err);
    return false;
  }
};

export { pingHealthService, checkOnlineStatus };
