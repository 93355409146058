import {
  calculateLRC,
  commandCode,
  getHexLengthBytes,
  getPayloadLengthBytes,
  toHex,
} from './utils';

const receiptRequest = transactionId => {
  const mid = 0;
  const messageIdentifier = toHex('V2');

  const requestMessage = [];
  requestMessage.push(commandCode.RECEIPT_REQUEST);
  requestMessage.push(transactionId.toString());
  requestMessage.push(mid.toString());

  const payload = Buffer.from(requestMessage.join());
  const payloadHex = payload.toString('hex');

  const calculatedLRC = calculateLRC(payloadHex);
  const payloadLengthBytes = getPayloadLengthBytes(payload.length);
  const hexLengthBytes = getHexLengthBytes(payloadLengthBytes);
  const buffer = messageIdentifier + hexLengthBytes + payloadHex + calculatedLRC;

  return buffer;
};

export { receiptRequest };
