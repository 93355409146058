// App is for all the internal product setup.
import React from 'react';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import { register as registerServiceWorker } from '@fingermarkglobal/service-worker/register';

import { Routes } from './routes';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

const ldConfig = {
  clientSideID: process.env.POI_APP_LAUNCHDARKLY_CLIENT_SIDE_ID,
  user: {
    key: process.env.POI_APP_LAUNCHDARKLY_SDK_KEY,
  },
};

registerServiceWorker({ path: './serviceWorker.js ' });

if (process.env.NODE_ENV === 'development') {
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

const Application = withLDProvider(ldConfig)(() => <Routes />);

export { Application };
