const getCardNumber = orderReceipt => {
  const receiptLines = orderReceipt.split('\n');

  // Card number line must have both
  const subStrArr = ['CARD', '....'];

  const lines =
    receiptLines.map(line => {
      const isCardLine = subStrArr.every(subStr => {
        return line.includes(subStr);
      });

      if (isCardLine) {
        const cardLine = line.trim().replace(/\D/g, '');

        if (cardLine.length > 2) {
          const number = cardLine.substr(-2);

          return number;
        }
      }

      return null;
    }) || null;

  if (lines) {
    const cardNumber = lines.filter(item => item);

    if (cardNumber.length === 1) {
      return cardNumber.shift();
    }
  }

  return null;
};

export { getCardNumber };
