import { assign } from 'xstate';
import { decryptResponse } from '../request';

const handlePayment = assign((context, event) => {
  const { config } = context;
  const { response, result, errorCondition, transactionId, timestamp, info } = event.data;

  return {
    transactionId,
    timestamp,
    result,
    config,
    response,
    info,
    message: errorCondition,
  };
});

const destructPayment = ({ response = null, config = {} } = {}) => {
  const payload = decryptResponse({ response, config });

  logger.info(
    `[@fingermarkglobal/adyen][destructPayment] payment decrypted payload response: ${JSON.stringify(
      payload,
      null,
      2,
    )}`,
  );

  const { SaleToPOIResponse } = payload || {};
  const { PaymentResponse } = SaleToPOIResponse || {};
  const { Response, POIData, PaymentResult } = PaymentResponse || {};
  const { Result, AdditionalResponse = '' } = Response || {};
  const { POITransactionID = {} } = POIData || {};
  const { TimeStamp: timestamp = null, TransactionID: transactionId = null } =
    POITransactionID || {};

  const parsedAdditionalResponse = new URLSearchParams(AdditionalResponse);
  const refusalReason = parsedAdditionalResponse.get('refusalReason') || 'No refusal reason';

  const { PaymentAcquirerData, PaymentInstrumentData, AmountsResp } = PaymentResult || {};
  const { AuthorizedAmount: amount = null } = AmountsResp || {};
  const { ApprovalCode: authCode = '' } = PaymentAcquirerData || {};
  const { CardData } = PaymentInstrumentData || {};
  const { PaymentBrand: cardType = null, MaskedPan: cardNumber = null } = CardData || {};

  // cancelled, notallowed, success
  const result = Result?.toLowerCase();
  const errorCondition = refusalReason || null;

  const info = {
    paymentType: 'CARD',
    transactionId,
    authCode,
    cardNumber,
    cardType,
    amount: parseFloat(amount),
  };

  return { response: payload, result, timestamp, transactionId, info, errorCondition };
};

export { handlePayment, destructPayment };
