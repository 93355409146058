import { ChromeSerialSettings } from '@fingermarkglobal/types';
import {
  retry,
  timeout,
  request,
  panthorEndpoint,
  HTTPError,
  TimeoutError,
  getJwtToken,
} from '@fingermarkglobal/request';

const getCategoriesRequest = async (settings: ChromeSerialSettings) => {
  const organisationId = process.env.POI_APP_ORGANISATION_ID || null;
  const { storeId: id } = settings.settings.datasource;

  try {
    const token = await getJwtToken();

    const categories = await request
      .get(`${panthorEndpoint}/menu/categories/${organisationId}/${id}`, {
        retry,
        timeout,
        headers: {
          Authorization: `Bearer ${token}`,
          'x-api-apex-logging-project': process.env.POI_APP_LOGGER_PROJECT,
        },
      })
      .json();

    return categories;
  } catch (err) {
    if (err instanceof HTTPError || err instanceof TimeoutError) throw err;
    logger.error('[@fingermarkglobal/restaurant] Error in getCategoriesRequest:', err);
    throw err;
  }
};

export { getCategoriesRequest };
