import { timestamp } from '@fingermarkglobal/utilities';
import { buildRequest } from '../request';
import { buildTransactionId } from '../utilities';

const buildPayment = ({ amount, config, transactionId = null } = {}) => {
  const category = 'Payment';
  const { order, settings } = config;
  const { siteId, storeId, kioskName, referenceType: format, currency } = settings;
  const { orderId } = order;

  const values = { siteId, storeId, kioskName, orderId };

  const TransactionID = transactionId || buildTransactionId({ format, values });

  // https://docs.adyen.com/point-of-sale/make-a-payment
  const body = {
    PaymentRequest: {
      SaleData: {
        SaleTransactionID: {
          TransactionID,
          TimeStamp: timestamp(),
        },
      },
      PaymentTransaction: {
        AmountsReq: {
          Currency: currency,
          RequestedAmount: amount,
        },
      },
    },
  };

  return {
    payload: buildRequest({ body, category, config }),
    transactionId: TransactionID,
  };
};

export { buildPayment };
