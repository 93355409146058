import { FallbackLogger } from '@fingermarkglobal/logger';

export { doPreOrder } from './order/do-pre-order';
export { preOrder } from './order/requests/pre-order';

export { doPlaceOrder } from './order/do-place-order';
export { placeOrder } from './order/requests/place-order';

export { doSaveOrder } from './order/do-save-order';
export { saveOrder } from './order/requests/save-order';

export { doCancelOrder } from './order/do-cancel-order';
export { cancelOrder } from './order/requests/cancel-order';

declare global {
    interface Window {
            logger: any
    }
}

window.logger = window.logger || new FallbackLogger();
