import loglevel from 'loglevel';
import stringify from 'stringify-object';

import { format } from 'date-fns';
import { Client } from 'apex-logs';
import { isObject } from 'mout/lang';
import { validate } from '@fingermarkglobal/validation';
import { mergeEventObject, getRelevantApexLevel } from '../utilities';

class ApexLogger {
  constructor({
    fields = {},
    token = null,
    project = null,
    endpoint = null,
    interval = 30,
    level = 'trace',
  } = {}) {
    validate({ name: 'Logger', parameters: { token, project, endpoint } });

    this.fields = fields;
    this.project = project;
    this.interval = interval;
    this.level = level;

    this.client = new Client({
      url: endpoint,
      authToken: token,
    });

    this.init();
  }

  init() {
    this.events = {};
    this.scopes = {};
    this.additionalFields = {};
    this.overrideProject = null;

    this.loglevel = loglevel;

    // Show only log levels based on `level`
    this.loglevel.setDefaultLevel(this.level);

    this.batcher = setInterval(async () => {
      // Snapshot before execution
      const snapshot = { ...this.events };

      try {
        // Reset events so any new ones can que while network requests are happening
        this.events = {};

        await Promise.all(
          Object.entries(snapshot).map(([project_id, events]) => {
            // Early abort if no events...
            if (!events.length) return Promise.resolve();

            return this.client.addEvents({
              project_id,
              events,
            });
          }),
        );
      } catch (error) {
        // Copy snapshot back to begining of array for next iteration...
        this.events = mergeEventObject({ previous: snapshot, next: this.events });

        this.loglevel.error(error);
      }
    }, 1000 * this.interval);
  }

  clearScopes() {
    this.scopes = {};
  }

  updateScopes({ name = null, fields = {} } = {}) {
    validate({ name: 'updateScopes', parameters: { name } });

    this.fields = {
      ...this.fields,
      [name]: {
        ...(this.fields[name] || {}),
        ...fields,
      },
    };
  }

  clearOverrideProject() {
    this.overrideProject = null;
  }

  updateOverrideProject({ project = null } = {}) {
    validate({ name: 'updateOverrideProject', parameters: { project } });

    this.overrideProject = project;
  }

  clearAdditionalFields() {
    this.additionalFields = {};
  }

  updateAdditionalFields({ fields = {} } = {}) {
    this.additionalFields = {
      ...this.additionalFields,
      ...fields,
    };
  }

  addEvent({ event = null, project = null } = {}) {
    validate({ name: 'addEvent', parameters: { event, project } });

    const events = this.events[project] || [];

    this.events = {
      ...this.events,
      [project]: [...events, event],
    };
  }

  addLog({ level = null, options = [] } = {}) {
    validate({ name: 'log', parameters: { level } });

    const loglevelNumber = loglevel.levels[level.toUpperCase()];

    if (loglevelNumber < this.loglevel.getLevel()) return;

    // Determine whether or not there are 'custom' options
    const duplicate = [...options];
    const config = duplicate.pop();
    const custom = isObject(config) && config.loggerOptions;

    // Set based on whether or not custom options exist
    const messages = custom ? duplicate : options;
    const fields = custom ? config.fields || {} : {};
    const scopes = custom ? config.scopes || [] : [];
    const project = custom ? config.project : this.overrideProject || this.project;

    const scoped = scopes.reduce(
      (result, next) => ({
        ...result,
        ...this.scopes[next],
      }),
      {},
    );

    const message = messages.reduce((result, next) => {
      if (!result.length) return stringify(next);
      // Using pipe to seperate the fields for Apex
      return `${result} | ${stringify(next)}`;
    }, new String());

    this.loglevel[level](...messages);

    this.addEvent({
      project,
      // Event structure...
      // https://github.com/apex/logs-js/blob/ba347a86135ed846c04a81ee04f0b00c53a7673a/client.ts#L76
      event: {
        level: getRelevantApexLevel({ level }),
        message,
        // Want to record this at the time of logging, not the time we send it...
        timestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        fields: {
          ...this.fields,
          ...this.additionalFields,
          ...scoped,
          ...fields,
        },
      },
    });
  }

  // Methods from loglevel...
  // https://github.com/pimterry/loglevel#documentation
  log(...args) {
    this.addLog({ level: 'debug', options: args });
  }

  info(...args) {
    this.addLog({ level: 'info', options: args });
  }

  warn(...args) {
    this.addLog({ level: 'warn', options: args });
  }

  trace(...args) {
    this.addLog({ level: 'trace', options: args });
  }

  debug(...args) {
    this.addLog({ level: 'debug', options: args });
  }

  error(...args) {
    this.addLog({ level: 'error', options: args });
  }
}

export { ApexLogger };
