import { validate } from '@fingermarkglobal/validation';

const registerSuccess = ({ path } = {}) => async () => {
  try {
    const registration = await window.navigator.serviceWorker.register(path);
    logger.debug(
      '[@fingermarkglobal/service-worker] registration successful with scope: ',
      registration.scope,
    );
  } catch (err) {
    logger.error('[@fingermarkglobal/service-worker] Error in registerSuccess:', err);
  }
};

const registerError = err =>
  logger.error('[@fingermarkglobal/service-worker] registration failed: ', err);

const register = ({ path = null } = {}) => {
  validate({ name: 'Service Worker Registration', parameter: { path } });

  if (!('serviceWorker' in window.navigator)) return;

  window.addEventListener('load', registerSuccess({ path }), registerError);
};

export { register };
