const commit = ({ createUpdater, setCart, product, format, cart, staged, reformat } = {}) => {
  const update = createUpdater({ set: setCart });

  if (product) {
    const commitable = format({ product, update, isRootProduct: true });
    return setCart(cartItems => [...cartItems, commitable]);
  }

  /**
   * Having an uid in the staged item means that it was reformatted
   * from the cart and we should remove the old version from the cart
   * to add the new object. We also have to make sure the products will
   * keep the same order.
   */
  const editingCartItemIndex = cart.findIndex(item => item.uid === staged.uid);

  if (editingCartItemIndex >= 0) {
    const editingCartItem = reformat({ product: staged, update, isRootProduct: true });
    const newCart = [...cart];
    newCart[editingCartItemIndex] = editingCartItem;
    return setCart([...newCart]);
  }

  setCart([...cart, reformat({ product: staged, update, isRootProduct: true })]);
};

const setValidatedValues = ({
  setCart,
  validatedOrderPayload,
  cart,
  formatValidatedValues,
} = {}) => {
  const { cart: validatedCart } = validatedOrderPayload || {};
  const { items: validatedItems = [] } = validatedCart || {};

  const formattedCart = cart.map(item => {
    const validatedItem = validatedItems.find(validatedItem => validatedItem.uid === item.uid);

    if (!validatedItem) return item;

    const { price: validatedPrice, isAvailable } = validatedItem;

    return formatValidatedValues({ item, price: validatedPrice, isAvailable });
  });

  setCart(formattedCart);
};

export { commit, setValidatedValues };
