import { CancelOrderPayload } from '@fingermarkglobal/types';
import { cancelOrder } from '../requests/cancel-order';
import { createMachine, assign } from 'xstate';

const populateMessage = assign({
  data: (_, payload: any) => {
    const message = payload?.message;
    const error = message?.message;
    const data = payload?.data;
    
    if (data) {
      logger.debug(`[@fingermarkglobal/api]: Cancel order FSM data recieved:`, data);
      return data
    };

    if (error) {
      logger.error(`[@fingermarkglobal/api]: Cancel order FSM error recieved:`, error);
      return error
    };

    logger.error(`[@fingermarkglobal/api]: Cancel order FSM message recieved:`, message);
    return message;
  }
});

const createCancelOrderMachine = ({ payload = null, timeout = 30, ip = undefined, provider = '' } = {}) =>
  createMachine<{ ip?: string; payload: CancelOrderPayload; timeout: number; data: unknown; provider: string }>(
    {
      id: 'cancelOrderMachine',
      initial: 'processing',
      context: {
        ip,
        payload,
        timeout,
        data: null,
        provider
      },
      states: {
        processing: {
          invoke: {
            src: ({ payload, timeout, ip }) => cancelOrder(payload, timeout, ip, provider),
            onDone: {
              target: 'success',
              actions: ['populateMessage']
            },
            onError: {
              target: 'error',
              actions: ['populateMessage']
            }
          }
        },
        success: {
          on: {
            RETRY: 'processing'
          }
        },
        error: {
          type: 'final',
          entry: ['populateMessage']
        }
      }
    },
    {
      actions: {
        populateMessage
      }
    }
  );

export { createCancelOrderMachine };
