const providerTypeFormatter = (provider: string): string => {
  const providerType: string = provider?.toLowerCase();

  if (providerType === 'mycentral') return 'mycentral-soap';

  return providerType;
};

const providerRequestFormatter = (request: string, provider: string): string => {
  return request?.replace('{provider}', provider);
};

export { providerTypeFormatter, providerRequestFormatter };
