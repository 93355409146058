import { check } from '@fingermarkglobal/validation';
import { NewEvent } from '@fingermarkglobal/event-store';
import { defaultRequest, publishEvent } from './default';
import { encodeProductDetails } from './util';

const registerProductClick = (
  { eventStoreEnabled },
  {
    sessionId,
    position,
    productVariant,
    categoryName,
    product,
    listName,
    storeAddress,
    organisation,
    serial,
    upsell = '',
  } = {},
) => {
  const invalidParams = check({
    name: 'Register product click',
    parameters: {
      sessionId,
      position,
      productVariant,
      categoryName,
      product,
      listName,
      storeAddress,
      organisation,
      serial,
      upsell,
    },
  });

  if (invalidParams) {
    logger.warn(
      `[@fingermarkglobal/google-analytics] Register product click invalid params: ${invalidParams}`,
    );
    return;
  }

  const additionalPayload = {
    t: `event`,
    ec: `Ecommerce`,
    ea: `Product Click`,
    pa: `click`,
    pal: `${encodeURIComponent(listName)}`,
  };

  const detailsToEncode = {
    eventAction: 'ProductClick',
    index: 1,
    sessionId,
    storeAddress,
    serial,
    listName,
    productVariant,
    organisation,
    categoryName,
    productName: product.name,
    productSku: product.id,
    position,
    upsell,
    additionalPayload,
  };

  const encodedPayload = encodeProductDetails(detailsToEncode);

  Object.keys(additionalPayload).forEach(name => {
    encodedPayload.push(`${name}=${encodeURIComponent(additionalPayload[name])}`);
  });

  defaultRequest(encodedPayload.join('&'));

  if (eventStoreEnabled) {
    const e = NewEvent(
      process.env.POI_APP_EVENT_STORE_SOURCE,
      process.env.POI_APP_EVENT_STORE_APP,
      'UserInteractionRecorded',
      {},
      detailsToEncode,
    );

    publishEvent(e);
  }
};

export { registerProductClick };
