import { assign } from 'xstate';
import { buildRefund, buildStatus } from '../../../utilities/builders';

const createPayload = assign((context, event) => {
  logger.info('[@fingermarkglobal/adyen] Refund transaction started...');

  const { transactionId, timestamp, config } = event;
  const payload = buildRefund({ transactionId, timestamp, config });

  const { payload: statusPayload } = buildStatus({ config });

  return {
    config,
    request: payload,
    statusRequest: statusPayload,
  };
});

export { createPayload };
