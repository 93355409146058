import { check } from '@fingermarkglobal/validation';
import { request, HTTPError, TimeoutError, getJwtToken } from '@fingermarkglobal/request';
import { buildRequestOptions } from '../helpers';
import { SaveOrderPayload } from '@fingermarkglobal/types'

const saveOrder = async (payload: SaveOrderPayload, timeout: number = 30): Promise<unknown> => {

  logger.info('[@fingermarkglobal/fm-api] saveOrder started');

  try {
    const jwtToken: string = await getJwtToken();
    const endpoint: string = process.env.POI_APP_HAWKE_SAVE_ORDER_ENDPOINT;

    const invalid: string = check({
      name: 'saveOrder',
      parameters: { payload }
    });

    if (invalid)
      throw new Error(`Fail to validate parameters in 'saveOrder': ${invalid}`);

    if (!jwtToken)
      throw new Error(`Unable to generate 'jwtToken' in 'saveOrder'`);

    if (!endpoint) throw new Error(`Invalid 'endpoint' provided in 'saveOrder'`);

    const { endpoint: formattedEndpoint, params } = buildRequestOptions(
      endpoint,
      payload,
      jwtToken,
      timeout,
    );

    const response: unknown = await request.post(formattedEndpoint, params).json();

    if (!response)
      throw new Error(
        `Invalid response returned from: ${formattedEndpoint} in 'saveOrder'`
      );

    logger.info('[@fingermarkglobal/fm-api] saveOrder finished');

    return response;
  } catch (err) {
    if (err instanceof HTTPError || err instanceof TimeoutError) throw err;
    logger.error('[@fingermarkglobal/fm-api] Error in saveOrder:', err);
    throw err;
  }
};

export { saveOrder };
