import { useCallback } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { cartState, formattedCartState, secondaryCartState } from '../../atoms';
import { format } from '../../utilities/format';
import { reformat } from '../../utilities/reformat';
import { createUpdater } from '../../utilities/update';
import { commit as standardCommit, setValidatedValues } from './utilities';
import { formatValidatedValues } from '../../utilities/format/utilities/validate';

const useCart = () => {
  const [cart, setCart] = useRecoilState(cartState);
  const items = useRecoilValue(formattedCartState);
  const resetCart = useResetRecoilState(cartState);

  /**
   * Commit item to the `cart`
   * @param {Object} params
   * @param {any} params.staged Staged product
   * @param {any} params.product Hydrated product
   */
  const commit = ({ staged, product }) => {
    standardCommit({
      cart,
      createUpdater,
      format,
      product,
      reformat,
      setCart,
      staged,
    });
  };

  /**
   * Update the cart tree with the validated values.
   * @param {Object} params
   * @param {any} params.validatedOrderPayload Validated response from pre order
   */
  const validate = ({ validatedOrderPayload } = {}) =>
    setValidatedValues({ cart, setCart, validatedOrderPayload, formatValidatedValues });

  return { commit, cart, items, resetCart, validate };
};

/**
 * This hook acts like a sub-branch of `useCart`.
 * Beyond the commit we can `push` current changes to `cart` and also `pull` items from `cart`.
 */
const useSecondaryCart = () => {
  const [secondaryCart, setSecondaryCart] = useRecoilState(secondaryCartState);
  const [cart, setCart] = useRecoilState(cartState);
  const resetSecondaryCart = useResetRecoilState(secondaryCartState);

  /**
   * Commit item to the `secondaryCart`
   * @param {Object} params
   * @param {any} params.staged Staged product
   * @param {any} params.product Hydrated product
   */
  const commit = ({ staged, product }) => {
    standardCommit({
      cart: secondaryCart,
      createUpdater,
      format,
      product,
      reformat,
      setCart: setSecondaryCart,
      staged,
    });
  };

  /**
   * Pull items from `cart` to the `secondaryCart`
   */
  const pull = useCallback(() => {
    const update = createUpdater({ set: setSecondaryCart });
    /**
     * When pulling the products from the main cart we force
     * to set the same `validated` value to respect the validated prices rule.
     */
    setSecondaryCart([
      ...cart.map(product => ({
        ...reformat({ product, update, isRootProduct: true }),
        validated: product.validated,
      })),
    ]);
    setCart([...cart.map(product => ({ ...product, evaluated: false }))]);
  }, [cart, setCart, setSecondaryCart]);

  /**
   * Push items from `secondaryCart` to the `cart`
   */
  const push = useCallback(() => {
    const update = createUpdater({ set: setCart });
    setCart([...secondaryCart.map(product => reformat({ product, update, isRootProduct: true }))]);
  }, [secondaryCart, setCart]);

  return { commit, secondaryCart, pull, push, resetSecondaryCart };
};

export { useCart, useSecondaryCart };
