/**
 * Index is being sent because of product loops
 *
 * Google Analytics tags
 *
 * cid: Anonymous Client ID.
 * dh: Document hostname (this is being used to send the store address)
 * cd2: custom setting: sending kiosk serial
 * pal: Product Action List
 *
 * Product related
 *
 * pr1va: Product 1 variant
 * pr1br: Product 1 brand
 * pr1ca: Product 1 category
 * pr1nm: Product 1 name. Either ID or name must be set.
 * pr1id: Product 1 id
 * pr1pr: Product 1 price
 * pr1qt: Product 1 quantity
 * pr1ps: Product 1 position
 * pr1cd<index>: Product 1 custom dimension
 *
 * Impression related
 *
 * il1pi1va: Impression 1 variant
 * il1pi1br: Impression 1 brand
 * il1pi1ca: Impression 1 category
 * il1pi1nm: Impression 1 name. Either ID or name must be set.
 * il1pi1id: Impression 1 id
 * il1pi1ps: Impression 1 position
 * il1pi1cd<index>: Impression 1 custom dimension
 * il1nm: Impression list 1. Required.
 */

const encodeProductDetails = ({
  index = 1,
  sessionId = null,
  storeAddress = null,
  serial = null,
  listName = null,
  productVariant = null,
  organisation = null,
  categoryName = null,
  productName = null,
  productSku = null,
  price = null,
  quantity = null,
  position = null,
  upsell = null,
  customDetail4 = null,
}) => {
  const payload = [];

  // First element empty to get and additional & at the start
  payload.push('');

  if (sessionId) payload.push(`cid=${sessionId}`);
  if (storeAddress) payload.push(`dh=${encodeURIComponent(storeAddress)}`);
  if (serial) payload.push(`cd2=${serial}`);
  if (listName) payload.push(`pal=${encodeURIComponent(listName)}`);

  if (productVariant) payload.push(`pr${index}va=${encodeURIComponent(productVariant)}`);
  if (organisation) payload.push(`pr${index}br=${encodeURIComponent(organisation)}`);
  if (categoryName) payload.push(`pr${index}ca=${encodeURIComponent(categoryName)}`);
  if (productName) payload.push(`pr${index}nm=${encodeURIComponent(productName)}`);
  if (productSku) payload.push(`pr${index}id=${productSku}`);
  if (price) payload.push(`pr${index}pr=${price}`);
  if (quantity) payload.push(`pr${index}qt=${quantity}`);
  if (upsell) payload.push(`pr${index}cd3=${upsell}`);
  if (position) payload.push(`pr${index}ps=${position}`);
  if (customDetail4) payload.push(`pr${index}cd4=${customDetail4}`);

  return payload;
};

const encodeImpressionDetails = ({
  index = 1,
  sessionId = null,
  storeAddress = null,
  serial = null,
  listName = null,
  productVariant = null,
  organisation = null,
  categoryName = null,
  productName = null,
  productSku = null,
  position = null,
  upsell = null,
}) => {
  const payload = [];

  // First element empty to get and additional & at the start
  payload.push('');

  if (sessionId) payload.push(`cid=${sessionId}`);
  if (storeAddress) payload.push(`dh=${encodeURIComponent(storeAddress)}`);
  if (serial) payload.push(`cd2=${serial}`);

  if (listName) payload.push(`il1nm=${encodeURIComponent(listName)}`);
  if (productVariant) payload.push(`il${index}pi1va=${encodeURIComponent(productVariant)}`);
  if (organisation) payload.push(`il${index}pi1br=${encodeURIComponent(organisation)}`);
  if (categoryName) payload.push(`il${index}pi1ca=${encodeURIComponent(categoryName)}`);
  if (productName) payload.push(`il${index}pi1nm=${encodeURIComponent(productName)}`);
  if (productSku) payload.push(`il${index}pi1id=${productSku}`);
  if (upsell) payload.push(`il${index}pi1cd3=${upsell}`);
  if (position) payload.push(`il${index}pi1ps=${position}`);

  return payload;
};

export { encodeProductDetails, encodeImpressionDetails };
