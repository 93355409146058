import { assign } from 'xstate';
import { v4 as uuidv4 } from 'uuid';
import { buildPayment, buildStatus } from '../../../utilities/builders';

const createPayload = assign((_, { amount, config, idempotencyKey = uuidv4() }) => {
  logger.info('[@fingermarkglobal/adyen] Payment transaction started...');

  const { payload, transactionId } = buildPayment({ amount, config });

  const { payload: statusPayload } = buildStatus({ config });

  return {
    config,
    request: payload,
    statusRequest: statusPayload,
    idempotencyKey,
    transactionId,
  };
});

export { createPayload };
