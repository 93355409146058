// Routes is for the applications workflow definitions.
import React from 'react';

import { RouteBuilder, base } from '@fingermarkglobal/router';
import { version } from './package.json';

const config = {
  ...base,
  admin: {
    ...base.admin,
    config: {
      ...base.admin.config,
      provider: { version },
    },
  },
};

const Routes = () => <RouteBuilder config={config} />;

export { Routes };
