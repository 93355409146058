/* eslint-disable max-lines */
import React from 'react';

import {
  RestaurantHomeView,
  RestaurantHomeViewProvider,
  RestaurantBeaconView,
  RestaurantBeaconViewProvider,
  RestaurantCouponViewProvider,
  RestaurantCouponView,
  RestaurantCategoriesViewProvider,
  RestaurantCategoriesView,
  RestaurantCategoryViewProvider,
  RestaurantCategoryView,
  RestaurantProductViewProvider,
  RestaurantProductView,
  RestaurantProductComboViewProvider,
  RestaurantProductComboView,
  RestaurantProductComposedViewProvider,
  RestaurantProductComposedView,
  RestaurantOrderValidationViewProvider,
  RestaurantOrderValidationView,
  RestaurantCartViewProvider,
  RestaurantCartView,
  PaymentOptionsViewProvider,
  PaymentOptionsView,
  CardPaymentView,
  CardPaymentViewProvider,
  RefundViewProvider,
  RefundView,
  RestaurantOrderConfirmationView,
  RestaurantOrderConfirmationViewProvider,
  ReceiptViewProvider,
  ReceiptView,
  AdminView,
  AdminViewProvider,
  NotificationView,
  NotificationProvider,
  AdminAuthenticateView,
  AdminAuthenticateProvider,
  RestaurantLockView,
  RestaurantLockViewProvider,
  SmsView,
  SmsViewProvider,
  RestaurantCustomerDetailsView,
  RestaurantCustomerDetailsViewProvider,
  TransactionsView,
  TransactionsViewProvider,
  InvalidRouteView,
  InvalidRouteViewProvider,
  ResetView,
  ResetViewProvider,
} from '@fingermarkglobal/cringer.components';
import { Redirect } from 'react-router-dom';

const base = {
  // maintains '/home' check in e2e tests
  redirect: {
    url: '/',
    View: ({ children }) => <Redirect to="/home">{children}</Redirect>,
    Provider: ({ children }) => <div>{children}</div>,
    config: {
      template: {},
      provider: {},
    },
  },
  home: {
    url: '/home',
    View: RestaurantHomeView,
    Provider: RestaurantHomeViewProvider,
    config: {
      template: {
        headerEnabled: false,
        footerEnabled: false,
        timeoutEnabled: false,
      },
      provider: {},
    },
  },
  reset: {
    url: '/reset',
    View: ResetView,
    Provider: ResetViewProvider,
    config: {
      template: {
        headerEnabled: false,
        footerEnabled: false,
        timeoutEnabled: false,
      },
      provider: {},
    },
  },
  beacon: {
    url: '/beacon',
    View: RestaurantBeaconView,
    Provider: RestaurantBeaconViewProvider,
    config: {
      template: {},
      provider: { primaryPath: '/categories', secondaryPath: '/reset' },
    },
  },
  coupon: {
    url: '/coupon',
    View: RestaurantCouponView,
    Provider: RestaurantCouponViewProvider,
    config: {
      template: {},
      provider: {},
    },
  },
  categories: {
    url: ['/categories', '/categories/:id'],
    View: RestaurantCategoriesView,
    Provider: RestaurantCategoriesViewProvider,
    config: { template: {}, provider: {} },
  },
  category: {
    url: '/category/:id',
    View: RestaurantCategoryView,
    Provider: RestaurantCategoryViewProvider,
    config: { template: {}, provider: {} },
  },
  product: {
    url: ['/category/:categoryId/product/:id', '/product/:id', '/product/:id/cart/:uid'],
    View: RestaurantProductView,
    Provider: RestaurantProductViewProvider,
    config: { template: {}, provider: {} },
  },
  combo: {
    url: ['/product/:productId/combo/:filterId', '/product/:productId/combo/:filterId/cart/:uid'],
    View: RestaurantProductComboView,
    Provider: RestaurantProductComboViewProvider,
    config: { template: {}, provider: {} },
  },
  composed: {
    url: '/product/:productId/composed',
    View: RestaurantProductComposedView,
    Provider: RestaurantProductComposedViewProvider,
    config: { template: {}, provider: {} },
  },
  validate: {
    url: '/validate',
    View: RestaurantOrderValidationView,
    Provider: RestaurantOrderValidationViewProvider,
    config: {
      template: {
        headerEnabled: false,
        footerEnabled: false,
        timeoutEnabled: false,
        templateOverride: true,
      },
      provider: {},
    },
  },
  cart: {
    url: '/cart',
    View: RestaurantCartView,
    Provider: RestaurantCartViewProvider,
    config: { template: {}, provider: { showCategoriesHeader: true } },
  },
  payment: {
    url: '/payment-options',
    View: PaymentOptionsView,
    Provider: PaymentOptionsViewProvider,
    config: {
      template: { headerEnabled: false, footerEnabled: false },
      provider: {},
    },
  },
  card: {
    url: '/payment',
    View: CardPaymentView,
    Provider: CardPaymentViewProvider,
    config: {
      template: {
        timeoutEnabled: false,
        headerEnabled: false,
        footerEnabled: false,
        templateOverride: true,
      },
      provider: {},
    },
  },
  refund: {
    url: '/refund',
    View: RefundView,
    Provider: RefundViewProvider,
    config: {
      template: {
        timeoutEnabled: false,
        headerEnabled: false,
        footerEnabled: false,
        templateOverride: true,
      },
      provider: { nextPath: '/reset' },
    },
  },
  confirmation: {
    url: '/checkout',
    View: RestaurantOrderConfirmationView,
    Provider: RestaurantOrderConfirmationViewProvider,
    config: {
      template: {
        timeoutEnabled: false,
        headerEnabled: false,
        footerEnabled: false,
        templateOverride: true,
      },
      provider: { nextPath: '/receipt' },
    },
  },
  receipt: {
    url: '/receipt',
    View: ReceiptView,
    Provider: ReceiptViewProvider,
    config: {
      template: { headerEnabled: false, footerEnabled: false },
      provider: {},
    },
  },
  admin: {
    url: '/admin',
    View: AdminView,
    Provider: AdminViewProvider,
    config: {
      template: {
        headerEnabled: false,
        footerEnabled: false,
        accessibilityActionEnabled: false,
      },
      provider: { version: '' },
    },
  },
  notify: {
    url: '/notify',
    View: NotificationView,
    Provider: NotificationProvider,
    config: {
      template: {
        headerEnabled: false,
        footerEnabled: false,
        timeoutEnabled: false,
      },
      provider: {},
    },
  },
  auth: {
    url: '/auth',
    View: AdminAuthenticateView,
    Provider: AdminAuthenticateProvider,
    config: {
      template: {
        headerEnabled: false,
        footerEnabled: false,
        timeoutEnabled: false,
        accessibilityActionEnabled: false,
      },
      provider: {},
    },
  },
  lock: {
    url: '/lock',
    View: RestaurantLockView,
    Provider: RestaurantLockViewProvider,
    config: {
      template: {
        headerEnabled: false,
        footerEnabled: false,
        timeoutEnabled: false,
        accessibilityActionEnabled: false,
      },
      provider: {},
    },
  },
  sms: {
    url: '/sms',
    View: SmsView,
    Provider: SmsViewProvider,
    config: {
      template: { headerEnabled: false, footerEnabled: false },
      provider: {},
    },
  },
  customer: {
    url: '/customer-details',
    View: RestaurantCustomerDetailsView,
    Provider: RestaurantCustomerDetailsViewProvider,
    config: {
      template: {},
      provider: { isFirstNameRequired: true, isLastNameRequired: true },
    },
  },
  transactions: {
    url: '/transactions',
    View: TransactionsView,
    Provider: TransactionsViewProvider,
    config: {
      template: {
        headerEnabled: false,
        footerEnabled: false,
        accessibilityActionEnabled: false,
      },
      provider: {},
    },
  },
  invalid: {
    url: '*',
    View: InvalidRouteView,
    Provider: InvalidRouteViewProvider,
    config: { template: {}, provider: {} },
  },
};

export { base };
