import { format } from '../../utilities/format';
import { useCallback, useRef, useState } from 'react';
import { evaluate } from '../../utilities/evaluate';
import { createUpdater } from '../../utilities/update';

const useScale = () => {
  const [tempStaged, setTempStaged] = useState();
  const stagedRef = useRef();

  const addOriginalProductCustomisations = ({ originalItem } = {}) => {
    if (!originalItem) return;
    const { options: originalProductOptions = [] } = originalItem;

    originalProductOptions.map((originalOption, optionIndex) => {
      originalOption.products.map((originalProduct, productIndex) => {
        if (originalProduct.selected) {
          if (!stagedRef.current?.options[optionIndex]?.products[productIndex]?.selected) {
            stagedRef.current.options[optionIndex].products[productIndex]?.select();
          }
        }
      });
    });

    const { options: newProductOptions } = stagedRef.current;

    const preparedOptions = newProductOptions.map((newOption, optionIndex) => {
      const preparedProducts = newOption.products.map((newProduct, productIndex) => {
        const currentOriginalProduct = originalProductOptions[optionIndex]?.products[productIndex];
        if (currentOriginalProduct.selected) {
          return { ...newProduct, customisations: currentOriginalProduct.customisations };
        }
        return { ...newProduct };
      });
      return { ...newOption, products: preparedProducts };
    });

    stagedRef.current = { ...stagedRef.current, options: preparedOptions };
  };

  const setScaleHandler = useCallback((valueOrUpdater, originalItem) => {
    const contextValue = stagedRef.current;
    if (typeof valueOrUpdater === 'function') {
      stagedRef.current = evaluate({ product: valueOrUpdater(contextValue) });
    } else {
      stagedRef.current = evaluate({ product: valueOrUpdater });
    }

    addOriginalProductCustomisations({ originalItem });

    logger.debug('[@fingermarkglobal/cart][setScaleHandler] Staged:', stagedRef.current);
    setTempStaged(stagedRef.current);
  }, []);

  const scaleStage = useCallback(
    ({ product } = {}, originalItem) => {
      const update = createUpdater({ set: setScaleHandler });
      setScaleHandler(format({ product, update, isRootProduct: true }), originalItem);
    },
    [setScaleHandler],
  );

  const scale = ({ originalItem, sizeToScale, product } = {}) => {
    const { id: originalProductId } = originalItem;

    const scaleOptions = {
      down: originalProductId - 1,
      toRegular: 0,
      up: originalProductId + 1,
      toLarge: product.options.length - 1,
    };

    const newItem = product.options.find(
      option => `${option.id}` === `${scaleOptions[sizeToScale]}`,
    );
    scaleStage(
      {
        product: {
          ...product,
          ...newItem,
          name: product.name,
          filterName: newItem.name,
        },
      },
      originalItem,
    );
  };

  return {
    tempStaged,
    scale,
  };
};

export { useScale };
