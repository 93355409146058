import { date, define, object, string, validate } from 'superstruct';
import isUuid from 'is-uuid';
import { v4 as uuid } from 'uuid';

const Uuid = define('Uuid', isUuid.v4);

const Event = object({
  id: Uuid,
  source: string(),
  event_type: string(),
  aggregate_type: string(),
  aggregate_id: Uuid,
  metadata: object(),
  payload: object(),
  timestamp: date(),
});

const validateEvent = (value = null) => {
  if (!value) return null;

  const [, valid] = validate(value, Event);

  if (!valid) {
    throw new Error('Event value does not match Event schema');
  } else {
    return value;
  }
};

const NewEvent = (source, aggregateType, eventType, metadata = {}, payload = {}) => {
  return {
    id: uuid(),
    source,
    event_type: eventType,
    aggregate_type: aggregateType,
    aggregate_id: uuid(),
    metadata,
    payload,
    timestamp: new Date(),
  };
};

export { Event, NewEvent, validateEvent };
