import { timestamp as time } from '@fingermarkglobal/utilities';
import { assign } from 'xstate';
import { TransactionResult } from '../../../utils';

const createPayload = assign((context, { amount, config }) => {
  return { amount, config };
});

const handlePayment = assign((context, event) => ({ ...context, ...event.data }));

const respondAfter = (resolve, reject, data, timeout) => {
  logger.info('[@fingermarkglobal/transactions][payment] Mock response', data);

  setTimeout(() => {
    try {
      if (data.result === TransactionResult.SUCCESS) {
        resolve(data);
      } else {
        reject(data.errorCondition);
      }
    } catch (err) {
      reject(err);
      logger.error('[@fingermarkglobal/payment][respondAfter] Error in respondAfter:', err);
    }
  }, timeout);
};

const executePayment = context =>
  new Promise((resolve, reject) => {
    const { amount } = context;

    const response = {};

    const result = TransactionResult.SUCCESS;
    const transactionId = 'MOCK12345';
    const timestamp = time();
    const errorCondition = null;
    const timeout = 5 * 1000;

    const authCode = '12345';
    const cardNumber = '**** **** **** **20';
    const cardType = 'VISA';

    const info = {
      paymentType: 'CARD',
      transactionId,
      authCode,
      cardNumber,
      cardType,
      amount,
      response,
    };
    const defaultData = {
      result,
      errorCondition,
      response,
      transactionID: transactionId,
      timestamp,
      info,
    };

    switch (true) {
      case amount >= 70 && amount <= 79.99: {
        const data = {
          ...defaultData,
          result: TransactionResult.NOT_ALLOWED,
          errorCondition: 'payment failure',
        };

        return respondAfter(resolve, reject, data, timeout);
      }
      case amount >= 80 && amount <= 89.99: {
        const data = {
          ...defaultData,
          result: TransactionResult.NOT_ALLOWED,
          errorCondition: 'payment failure (after 15s)',
          response: {
            data: { message: 'something went wrong with payment' },
          },
        };

        return respondAfter(resolve, reject, data, 15 * 1000);
      }
      case amount >= 90 && amount <= 99.99: {
        const data = {
          ...defaultData,
          result: TransactionResult.NOT_ALLOWED,
          errorCondition: 'payment failure (timeout)',
        };

        return respondAfter(resolve, reject, data, 100 * 1000);
      }
      default:
        return respondAfter(resolve, reject, defaultData, timeout);
    }
  });

export { createPayload, handlePayment, executePayment };
