import { interpret } from '@fingermarkglobal/utilities';
import { createPreOrderMachine } from './machines/pre-order';
import { providerTypeFormatter } from './helpers';
const doPreOrder = ({ payload = null, timeout = 30, transition = null, ip, provider: type = '' }) => {
  const provider = providerTypeFormatter(type);
  const machine = createPreOrderMachine({ payload, timeout, ip, provider});

  const { instance: preOrderMachine } = interpret({
    name: 'preOrderMachine',
    machine,
    transition,
  });

  return { preOrderMachine };
};

export { doPreOrder };
