const buildRoutes = ({ config, Template, Route } = {}) => {
  const array = Object.values(config);

  // Add in defaults if missing, keep overrides if present
  return array.map(route => {
    if (!route) return route;
    return {
      ...route,
      Template: route.Template ? route.Template : Template,
      Route: route.Route ? route.Route : Route,
    };
  });
};

export { buildRoutes };
