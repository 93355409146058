import { nanoid } from 'nanoid';
import {
  createAdder,
  createEditor,
  createRemover,
  createSelector,
  createSetValidatedValues,
  createRemoverAll,
} from './handlers';
import { formatCategory } from './utilities';
import { validate } from '@fingermarkglobal/validation';
import { formatCount } from './utilities/count';

// `format` goes from a hydrated product to a formatted, actionable
// product (add, remove etc)
const format = ({
  product,
  update,
  category = null,
  isRootProduct = false,
  categoryUid = null,
} = {}) => {
  validate({ name: 'Cart format', paramaters: { product, update } });
  const {
    id,
    productId,
    name = {},
    options = [],
    products = [],
    uid = nanoid(),
    categories = [],
    type = 'No type found',
    imageUrl = 'No image found',
    price: unitPrice,
    default: isDefault = false,
    providerData = {},
    energyInformation,
    description = {},
    filterName = {},
    scalable,
    sku,
    isAvailable = true,
    allergens = [],
  } = product;

  const formatAllergens = allerger => {
    if (!allerger || typeof allerger !== 'string') return;

    const stringWithouUnderline = allerger.split('_').join(' ');
    const allergerFormatted =
      stringWithouUnderline.charAt(0).toUpperCase() + stringWithouUnderline.slice(1);

    return allergerFormatted;
  };
  const allergensFormatted = allergens.map(allerger => formatAllergens(allerger));

  // if `category` is present it comes from a customisation
  // category, this means it has extra maxes
  const { max: categoryMax = null } = category || {};

  const count = formatCount({ product, isRootProduct });
  const { current } = count;

  const priceCalc = Math.max(0, count.current - count.initial) * unitPrice;

  const price = {
    initial: isRootProduct ? 0 : priceCalc,
    current: isRootProduct ? unitPrice : priceCalc,
    unit: unitPrice,
  };

  const selected = current > 0;

  const remove = createRemover({ uid, update, isRootProduct, category });
  const select = createSelector({ uid, update, categoryUid });
  const add = createAdder({ uid, update, categoryMax, isRootProduct, category });
  const removeAll = createRemoverAll({ uid, update });
  const edit = createEditor({ uid, update });
  const setValidatedValues = createSetValidatedValues({ uid, update });

  const formatted = products.map(product => format({ product, update }));

  const formattedOptions = options.map(category => formatCategory({ category, update, id }));

  const formattedCustomisations = categories.map(category =>
    formatCategory({ category, update, id }),
  );

  return {
    productId,
    id,
    uid,
    add,
    type,
    name,
    count,
    remove,
    removeAll,
    select,
    edit,
    setValidatedValues,
    selected,
    imageUrl,
    providerData,
    default: isDefault,
    products: formatted,
    options: formattedOptions,
    price,
    isRootProduct,
    customisations: formattedCustomisations,
    removeDisabled: isRootProduct ? current <= 1 : current <= count.min,
    isAvailable,
    validated: false,
    addDisabled: count.max !== -1 && current >= count.max,
    energyInformation,
    description,
    editDisabled: type === 'simple',
    filterName,
    evaluated: false,
    scalable,
    sku,
    allergens: allergensFormatted,
  };
};

export { format };
