import { destructRefund, destructStatus } from '../../destructerers';
import { executeRequest } from '../request';

const executeRefund = async context => {
  // take built payload
  const { config } = context;

  let adyenResponse;
  let status = false;

  // make request
  logger.info(
    `[@fingermarkglobal/adyen][executeRefund] refund request: ${JSON.stringify(context, null, 2)}`,
  );

  try {
    adyenResponse = await executeRequest({ context });

    logger.info(
      `[@fingermarkglobal/adyen][executeRefund] refund response: ${JSON.stringify(
        adyenResponse,
        null,
        2,
      )}`,
    );

    // handle destruct
    // return standard format
    const { result, errorCondition, response } = destructRefund({
      response: adyenResponse,
      config,
    });

    if (result === 'success') return Promise.resolve({ result, errorCondition, response });

    throw new Error('Refund failed');
  } catch (error) {
    logger.warn(
      `[@fingermarkglobal/adyen][executeRefund] refund request failed. trying to get transaction status`,
    );

    adyenResponse = await executeRequest({ context, status: true });

    status = true;

    logger.info(
      `[@fingermarkglobal/adyen][executeRefund] status response: ${JSON.stringify(
        adyenResponse,
        null,
        2,
      )}`,
    );

    // handle destruct
    // return standard format
    const { result, errorCondition, response } = destructStatus({
      response: adyenResponse,
      config,
      status,
    });

    if (result === 'success') return Promise.resolve({ result, errorCondition, response });

    return Promise.reject({ result, errorCondition, response });
  }
};

export { executeRefund };
