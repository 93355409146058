import { assign } from 'xstate';

const handleRefund = assign((context, event) => {
  const { request, config } = context;
  const { amount, transactionRequest } = request;
  const { transactionId } = transactionRequest;

  const { Payload } = event.data;
  const {
    response_issuername: cardType,
    response_emv_app_pan_key: cardNumber,
    response_authcode_key: authCode,
    response_tid_key: terminalId,
    response_mid_key: merchantId,
  } = Payload || {};

  // Amount is in cents
  const info = {
    paymentType: 'CARD',
    transactionId,
    terminalId,
    authCode,
    cardNumber,
    cardType,
    merchantId,
    amount: parseFloat(amount),
  };

  logger.info('[@fingermarkglobal/cbq-ecr] Refund transaction finished');

  return {
    transactionId,
    timestamp: new Date().toISOString(),
    result: 'SUCCESS',
    config,
    response: event.data,
    info,
    message: '',
  };
});

export { handleRefund };
