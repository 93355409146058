import {
  calculateLRC,
  commandCode,
  getHexLengthBytes,
  getPayloadLengthBytes,
  toHex,
} from './utils';

const createResultRequest = preTxId => {
  const mid = 0;
  const messageIdentifier = toHex('V2');

  const requestMessage = [];
  requestMessage.push(commandCode.RESULT_REQUEST);
  requestMessage.push(preTxId.toString());
  requestMessage.push(mid.toString());

  const payload = Buffer.from(requestMessage.join());
  const payloadHex = payload.toString('hex');

  // Longitudinal redundancy check (LRC)
  const calculatedLRC = calculateLRC(payloadHex);

  // Payload Length in Bytes
  const payloadLengthBytes = getPayloadLengthBytes(payload.length);
  const hexLengthBytes = getHexLengthBytes(payloadLengthBytes);
  const buffer = messageIdentifier + hexLengthBytes + payloadHex + calculatedLRC;

  return buffer;
};

export { createResultRequest };
