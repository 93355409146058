import { validate } from '@fingermarkglobal/validation';
import { interpret } from '@fingermarkglobal/utilities';
import { createPlaceOrderMachine } from './machines/place-order';
import { providerTypeFormatter } from './helpers';
const doPlaceOrder = ({ payload = null, timeout = 30, transition = null, ip, provider: type = '' }) => {
  validate({ name: 'doPlaceOrder', parameters: { payload } });
  const provider = providerTypeFormatter(type);
  const machine = createPlaceOrderMachine({ payload, timeout, ip, provider });

  const { instance: placeOrderMachine } = interpret({
    name: 'placeOrderMachine',
    machine,
    transition,
  });

  return { placeOrderMachine };
};

export { doPlaceOrder };
