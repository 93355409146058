import { executeRequest } from '../request';

const sendFlush = ({ payload, config, endpoint }) => {
  logger.info(
    `[@fingermarkglobal/cbqEcr][executeTransaction] flush request: ${JSON.stringify(
      payload,
      null,
      2,
    )}`,
  );

  return executeRequest({ payload, config, endpoint });
};

export { sendFlush };
