import { validate } from '@fingermarkglobal/validation';
import { request, HTTPError, TimeoutError } from '../request';

const getJwtToken = async () => {
  const jwtEndpoint = process.env.POI_APP_JWT_ENDPOINT || null;

  try {
    validate({ name: 'getJwtToken', parameters: { jwtEndpoint } });

    const response = await request
      .get(jwtEndpoint, {
        headers: {
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        },
      })
      .text();

    return response;
  } catch (err) {
    if (err instanceof HTTPError || err instanceof TimeoutError) throw err;
    logger.error('[@fingermarkglobal/utilities] Error in getJwtToken:', err);
    throw err;
  }
};

export { getJwtToken };
