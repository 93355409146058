import {
  calculateLRC,
  commandCode,
  getHexLengthBytes,
  getPayloadLengthBytes,
  toHex,
} from './utils';

const printResponse = () => {
  const messageIdentifier = toHex('V2');

  const requestMessage = [];
  requestMessage.push(commandCode.PRINT_RESPONSE);
  requestMessage.push('OK');

  const payload = Buffer.from(requestMessage.join());
  const payloadHex = payload.toString('hex');

  const calculatedLRC = calculateLRC(payloadHex);
  const payloadLengthBytes = getPayloadLengthBytes(payload.length);
  const hexLengthBytes = getHexLengthBytes(payloadLengthBytes);
  const buffer = messageIdentifier + hexLengthBytes + payloadHex + calculatedLRC;

  return buffer;
};

export { printResponse };
