import { send } from '@fingermarkglobal/protocols';
import {
  specialCharacters,
  responseCodes,
  findCodeByTag,
  getTagNameByCode,
  getRefusalReason,
} from './enums';

import { getPrintStatusRequest } from './print-status';

const parseResponse = (payload, port, socket) => {
  const dataFields = new Map();

  const splittedPayload = payload.split(specialCharacters.FIELD_SEPARATOR);

  let status;

  splittedPayload.forEach((payloadField, index) => {
    if (index === 0) {
      const responseType = payloadField.substr(0, 2);

      switch (responseType) {
        case responseCodes.TERMINAL_BUSY: {
          throw new Error('Terminal not available');
        }
        case responseCodes.RECEIPT_INFORMATION: {
          const packageSequenceNumber = payloadField.substr(2, 1);

          if (`${packageSequenceNumber}` === `0`) {
            const printStatusRequest = getPrintStatusRequest();

            send(printStatusRequest, port, socket); // send print OK to terminal
          }
          break;
        }
        case responseCodes.TRANSACTION_RESPONSE: {
          const multiTrans = payloadField.substr(2, 1);

          if (`${multiTrans}` === '0') status = 'success';

          break;
        }
        default:
          throw new Error(getRefusalReason(responseType));
      }
    } else {
      const dataTag = payloadField.substr(0, 3);
      const value = payloadField.substr(3);

      dataFields.set(dataTag, value);
    }
  });

  const properties = Array.from(dataFields).map(([tag, value]) => {
    const namedTag = getTagNameByCode(tag);
    const namedValue = findCodeByTag(tag, value);

    return {
      [namedTag]: namedValue || value,
    };
  });

  const rawResponse = Object.assign({}, ...properties);

  return {
    status,
    rawResponse,
  };
};

export { parseResponse };
