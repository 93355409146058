import { createMachine, assign } from 'xstate';
import { placeOrder } from '../requests/place-order';
import { PlaceOrderPayload } from '@fingermarkglobal/types';

const populateMessage = assign({
  data: (_, payload: any) => {
    const message = payload?.message;
    const error = message?.message;
    const data = payload?.data;
    
    if (data) {
      logger.debug(`[@fingermarkglobal/api]: Place order FSM data recieved:`, data);
      return data
    };

    if (error) {
      logger.error(`[@fingermarkglobal/api]: Place order FSM error recieved:`, error);
      return error
    };

    logger.error(`[@fingermarkglobal/api]: Place order FSM message recieved:`, message);
    return message;
  }
});

const createPlaceOrderMachine = ({
  payload = null,
  timeout = 30,
  ip = undefined,
  provider = ''
} = {}) =>
  createMachine<{
    ip?: string;
    data: unknown;
    timeout: number;
    payload: null | PlaceOrderPayload;
    provider: string
  }>(
    {
      id: 'placeOrder',
      initial: 'processing',
      context: {
        ip,
        payload,
        timeout,
        data: null,
        provider
      },
      states: {
        processing: {
          invoke: {
            src: ({ payload, timeout, ip }) => placeOrder(payload, timeout, ip, provider),
            onDone: {
              target: 'success',
              actions: ['populateMessage']
            },
            onError: {
              target: 'error',
              actions: ['populateMessage']
            }
          }
        },
        success: {
          on: {
            // TODO rename this to `RETRY`
            IDLE: 'processing'
          }
        },
        error: { type: 'final', entry: ['populateMessage'] }
      }
    },
    {
      actions: {
        populateMessage
      }
    }
  );

export { createPlaceOrderMachine };
